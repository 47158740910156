
import Vue from "vue"
import { mapStores } from "pinia"
import { Context } from "@nuxt/types"
import { MetaInfo } from "vue-meta"
import { TimelineMarker, TimelineEvent, TimelineInterval } from "@evercam/ui"
import { useTimelineStore } from "@/stores/timeline/timeline"
import { useRecordingsStore } from "@/stores/recordings"
import { useSnapshotStore } from "@/stores/snapshots"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { useLayoutStore } from "@/stores/layout"
import { useProjectStore } from "@/stores/project"
import SampleMilestones from "@/components/timeline/sampleMilestones"
import TimelinePlayer from "@evercam/shared/components/timelinePlayer/TimelinePlayer"
import RightSidebarContent from "@/components/portals/RightSidebarContent"
import TimelinePlayerCommentTooltip from "@evercam/shared/components/timelinePlayer/tooltips/TimelinePlayerCommentTooltip"
import TimelinePlayerMediaHubMilestone from "@evercam/shared/components/timelinePlayer/tooltips/TimelinePlayerMediaHubMilestone"
import { useBimCompareStore } from "@/stores/bimCompare"
import {
  Camera,
  TimelinePlayerTooltipItem,
  TimelineUrlParams,
  TimelineMarkerId,
  TimelineGroupId,
  CameraStatus,
  CameraStatusLog,
  TimelineOverlayType,
  TimelineOverlayData,
  AnalyticsEvent,
  _360UrlParams,
  droneUrlParams,
  TimelinePrecision,
  CameraExid,
  AnalyticsEventPageId,
} from "@evercam/shared/types"
import SnapshotCard from "@evercam/shared/components/SnapshotCard"
import TheTimelineOverlay from "@/components/timeline/TheTimelineOverlay"
import TheTimelineSidebar from "@/components/timeline/TheTimelineSidebar"
import TheTimelineActionButtons from "@/components/timeline/TheTimelineActionButtons"
import { TimelineColors } from "@evercam/shared/constants/timeline"
import {
  debounce,
  clearQuery,
  extractParamsFromQuery,
  clearParamsFromQuery,
} from "@evercam/shared/utils"
import { useCompareStore } from "@/stores/compare"
import { useTimelineAnprGroupStore } from "@/stores/timeline/timelineAnprGroup"
import { useTimeline360GroupStore } from "@/stores/timeline/timeline360Group"
import { useTimelineDroneGroupStore } from "@/stores/timeline/timelineDroneGroup"
import { useTimelineMediaHubGroupStore } from "@/stores/timeline/timelineMediaHubGroup"
import { useTimelineBimGroupStore } from "@/stores/timeline/timelineBimGroup"
import { useTimelineMobileCaptureGroupStore } from "@/stores/timeline/timelineMobileCaptureGroup"
import { useTimelineExNvrGroupStore } from "@/stores/timeline/timelineExNvrGroup"
import { useTimelineSmartMilestonesGroupStore } from "@/stores/timeline/timelineSmartMilestonesGroup"
import { useTimelineSiteActivityGroupStore } from "@/stores/timeline/timelineSiteActivityGroup"
import { useTimelineSafetyReportsGroupStore } from "@/stores/timeline/timelineSafetyReportsGroup"
import { useTimelineConstructionReportsGroupStore } from "@/stores/timeline/timelineConstructionReportsGroup"
import { useTimelineCommentsGroupStore } from "@/stores/timeline/timelineCommentsGroup"
import CameraStatusSnackbar from "@/components/CameraStatusSnackbar.vue"
import ITwinBimCompare from "@/components/iTwin/ITwinBimCompare.vue"
import PlayersActionButtons from "@/components/recordings/player/PlayersActionButtons.vue"
import RecordingsPlayerXrayOverlay from "@/components/recordings/player/RecordingsPlayerXrayOverlay.vue"
import RecordingsPlayerCommentsOverlay from "@/components/recordings/player/RecordingsPlayerCommentsOverlay"
import SnapshotEditor from "@/components/SnapshotEditor.vue"

export default Vue.extend({
  name: "TheTimeline",
  components: {
    CameraStatusSnackbar,
    PlayersActionButtons,
    RightSidebarContent,
    TimelinePlayer,
    SnapshotCard,
    TimelinePlayerMediaHubMilestone,
    ITwinBimCompare,
    TheTimelineOverlay,
    TheTimelineSidebar,
    TimelinePlayerCommentTooltip,
    RecordingsPlayerXrayOverlay,
    RecordingsPlayerCommentsOverlay,
    SnapshotEditor,
    TheTimelineActionButtons,
  },
  async asyncData({ query }: Context): Promise<void> {
    await useTimelineStore().init(query as TimelineUrlParams)
  },
  meta: {
    pageId: AnalyticsEventPageId.timeline,
  },
  data() {
    return {
      activeMarkerId: undefined as string | undefined,
      focusedTimestamp: undefined as string | undefined,
      isDraggingMarker: false,
      hideTimeline: false,
      isDOMUpdateScheduled: false,
      isXrayInitialized: false,
      hasUserInteractedWithFilters: false,
      hasUserSelectedADate: false,
      colors: TimelineColors,
      compareMarkerlabelClass: "compare-label-text",
      mediaThumbnailWidth: 64,
      mobileCaptureThumbnailWidth: 120,
      TimelineGroupId,
      pollingIntervalId: 0,
      latestSnapshotTimestamp: "",
      focusedInterval: undefined as TimelineInterval | undefined,
      wasLive: false,
      urlParamsEnums: {
        [TimelineGroupId.Drone]: droneUrlParams,
        [TimelineGroupId._360]: _360UrlParams,
      },
      groupsEvents: {} as { [groupName: string]: TimelineEvent[] },
      groupsLoadingState: {} as {
        [groupName: string]: boolean
      },
      groupsVisibility: {} as {
        [groupName: string]: boolean
      },
      showThumbnails: false,
      precision: null as TimelinePrecision,
      isFetchingEvents: false,
      isInitialized: false,
      editorTranslation: 0,
      imageControlProps: {},
      isActionButtonsMenuHovered: false,
      previousTimestamp: undefined as string | undefined,
    }
  },
  head(): MetaInfo {
    let classList: string[] = []
    if (this.$vuetify.breakpoint.mdAndUp) {
      classList.push("overflow-y-hidden")
    }

    return {
      title: `${this.projectStore.selectedProject?.name} | ${this.$t(
        "pages.timeline"
      )}`,
      meta: [
        { charset: "utf-8" },
        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
      htmlAttrs: {
        class: classList.join(" "),
      },
    } as unknown as MetaInfo
  },
  computed: {
    ...mapStores(
      useTimelineStore,
      useCameraStore,
      useRecordingsStore,
      useSnapshotStore,
      useAccountStore,
      useLayoutStore,
      useProjectStore,
      useBimCompareStore,
      useTimelineAnprGroupStore,
      useTimeline360GroupStore,
      useTimelineDroneGroupStore,
      useTimelineMediaHubGroupStore,
      useTimelineBimGroupStore,
      useTimelineMobileCaptureGroupStore,
      useTimelineExNvrGroupStore,
      useTimelineSmartMilestonesGroupStore,
      useTimelineSiteActivityGroupStore,
      useTimelineSafetyReportsGroupStore,
      useTimelineConstructionReportsGroupStore,
      useCompareStore,
      useTimelineCommentsGroupStore
    ),
    hasFixedSidebarContent(): boolean {
      return (
        this.timelineStore.showCompare ||
        this.timelineCommentsGroupStore.showComments ||
        this.timelineBimGroupStore.showBim ||
        this.recordingsStore.isXrayActive
      )
    },
    hasExtensibleSidebarContent(): boolean {
      return (
        this.timelineSmartMilestonesGroupStore.showSmartMilestones ||
        this.timelineStore.isCopilotEnabled
      )
    },
    sidebarStates(): Record<string, boolean> {
      return {
        isFixed: this.hasFixedSidebarContent,
        isExtensible: this.hasExtensibleSidebarContent,
      }
    },
    shouldCloseCopilot() {
      return (
        this.hasFixedSidebarContent ||
        this.timelineSmartMilestonesGroupStore.showSmartMilestones
      )
    },
    isAutoPanDisabled(): boolean {
      return (
        this.recordingsStore.isXrayActive ||
        (this.recordingsStore.isLive && !this.hasUserSelectedADate) ||
        this.wasLive
      )
    },
    editorTranslationStyles() {
      return {
        transform: `translate(0, ${this.editorTranslation}px)`,
      }
    },
    fitMarkersOnChange(): boolean {
      if (this.recordingsStore.isXrayActive) {
        return !this.isXrayInitialized
      }

      if (this.timelineStore.showCompare) {
        return
      }

      return this.hasUserInteractedWithFilters
    },
    compareBeforeSnapshotMarkerTooltip(): TimelinePlayerTooltipItem {
      return {
        title: "Before",
        label: this.getFormattedTimestamp(this.compareStore.beforeTimestamp),
        thumbnailUrl: this.compareStore.snapshot.before,
      }
    },
    compareAfterSnapshotMarkerTooltip(): TimelinePlayerTooltipItem {
      return {
        title: "After",
        label: this.getFormattedTimestamp(this.compareStore.afterTimestamp),
        thumbnailUrl: this.compareStore.snapshot.after,
      }
    },
    currentSnapshotMarkerTooltip(): TimelinePlayerTooltipItem {
      return {
        title: "After",
        label: this.getFormattedTimestamp(
          this.recordingsStore.selectedTimestamp
        ),
        thumbnailUrl: this.recordingsStore.snapshotImgElement?.src,
      }
    },
    olderSnapshotMarkerTooltip(): TimelinePlayerTooltipItem {
      return {
        title: "Before",
        label: this.getFormattedTimestamp(
          this.recordingsStore.selectedXrayTimestamp
        ),
        thumbnailUrl: this.recordingsStore.selectedXraySnapshot,
      }
    },
    tlPlayerClasses(): Record<string, boolean> {
      return {
        [`the-timeline--has-overlay the-timeline--${this.timelineStore.overlayType}`]:
          this.timelineStore.showOverlay,
      }
    },
    playerHeight(): number {
      return this.layoutStore.mainContentRect.height
    },
    playerOverlayHeight(): number {
      return this.hideTimeline
        ? this.layoutStore.mainContentRect.height
        : this.layoutStore.mainContentRect.height -
            this.layoutStore.footerRect.height
    },
    playerWidth(): number {
      const sideBarWidth = this.layoutStore.isRightSidebarVisible
        ? this.layoutStore.rightSidebarWidth
        : 0

      return this.layoutStore.mainContentRect.width - sideBarWidth
    },
    cameraOfflineIntervals(): TimelineInterval[] {
      const cameraExid = this.cameraStore.selectedCamera?.id
      const logs = this.timelineStore.cameraStatusLogs
      if (!cameraExid || !logs?.length) {
        return [] as TimelineInterval[]
      }

      return logs.reduce((acc, statusLog: CameraStatusLog) => {
        if (statusLog.state === CameraStatus.ONLINE) {
          return acc
        }

        return [
          ...acc,
          {
            startDate: new Date(statusLog.start).getTime(),
            endDate: new Date(statusLog.end).getTime(),
          },
        ]
      }, [])
    },
    smartMilestones():
      | Array<Record<string, { title: string; description: string }>>
      | undefined {
      return SampleMilestones[this.projectStore.selectedProject.exid]
    },
    markers(): TimelineMarker[] {
      if (this.recordingsStore.isEditing) {
        return []
      }

      if (
        this.recordingsStore.isXrayActive &&
        this.recordingsStore.selectedXrayTimestamp
      ) {
        return this.xrayMarkers as TimelineMarker[]
      }

      if (
        this.timelineSmartMilestonesGroupStore.smartMilestones.length &&
        this.timelineSmartMilestonesGroupStore.showSmartMilestones
      ) {
        return this.smartMilestonesMarkers as TimelineMarker[]
      }

      if (
        this.timelineStore.showCompare &&
        this.compareStore.beforeTimestamp &&
        this.compareStore.afterTimestamp
      ) {
        return this.compareMarkers
      }

      return []
    },
    compareMarkers(): TimelineMarker[] {
      return [
        {
          id: TimelineMarkerId.CompareBeforeSnapshot,
          timestamp: this.compareStore.beforeTimestamp,
          maxDate: this.compareStore.afterTimestamp,
          label: "Before snapshot",
          color: this.colors.compareMarker,
          textColor: "#fff",
          isDraggable: true,
          className: "snapshot-marker",
        },
        {
          id: TimelineMarkerId.CompareAfterSnapshot,
          timestamp: this.compareStore.afterTimestamp,
          minDate: this.compareStore.beforeTimestamp,
          label: "After snapshot",
          color: this.colors.compareMarker,
          textColor: "#fff",
          isDraggable: true,
          className: "snapshot-marker",
        },
      ]
    },
    xrayMarkers(): TimelineMarker[] {
      return [
        {
          id: TimelineMarkerId.Xray,
          timestamp: this.recordingsStore.selectedXrayTimestamp,
          maxDate: this.recordingsStore.selectedTimestamp,
          label: "X-Ray snapshot",
          color: this.colors.compareMarker,
          textColor: "#fff",
          isDraggable: true,
          className: "snapshot-marker",
        },
        {
          id: TimelineMarkerId.CurrentSnapshot,
          timestamp: this.recordingsStore.selectedTimestamp,
          minDate: this.recordingsStore.selectedXrayTimestamp,
          label: "Current snapshot",
          color: this.colors.compareMarker,
          textColor: "#fff",
          isDraggable: true,
          className: "snapshot-marker",
        },
      ]
    },
    smartMilestonesMarkers(): TimelineMarker[] {
      return this.timelineSmartMilestonesGroupStore.smartMilestones.reduce(
        (acc, m) => {
          if (!m.response) {
            return acc
          }

          return [
            ...acc,
            {
              id: m.id,
              timestamp: m.toDate,
              label: m.response.title,
              color: this.colors.smartMilestone,
              textColor: "#fff",
            },
          ]
        },
        []
      )
    },
    totalVisibleGroups(): number {
      return Object.values(this.timelineStore.groupsVisibility).filter((v) => v)
        .length
    },
    minGroupHeight(): number {
      return this.totalVisibleGroups > 1 ? 38 : 75
    },
  },
  watch: {
    sidebarStates: {
      immediate: true,
      deep: true,
      handler({ isFixed, isExtensible }) {
        if (!isFixed && !isExtensible) {
          this.layoutStore.disableRightSidebar()

          return
        }

        if (isExtensible) {
          this.layoutStore.enableRightSidebar({ isResizable: true, width: 620 })
        } else {
          this.layoutStore.enableRightSidebar()
        }
      },
    },
    shouldCloseCopilot(value) {
      if (value) {
        this.timelineStore.isCopilotEnabled = false
      }
    },
    "timelineStore.overlayType": {
      handler(value) {
        if (value) {
          this.initOverlayDataFromUrl()
        }
      },
      immediate: true,
    },
    "timelineBimGroupStore.showBim": {
      handler(value) {
        if (value) {
          this.enableBimCompare()
        }
      },
      immediate: true,
    },
    "timelineStore.showCompare": {
      handler(value) {
        if (value) {
          this.enableCompare()
        }
      },
      immediate: true,
    },
    activeMarkerId(markerId?: string) {
      if (!markerId) {
        return
      }
      const markers = document.querySelectorAll(".e-timeline .marker")
      const selectedId = markerId
      markers.forEach((markerEl) => {
        if (selectedId && markerEl.getAttribute("data-id") === selectedId) {
          markerEl.classList.add("marker--highlighted")
        } else {
          markerEl.classList.remove("marker--highlighted")
        }
      })
    },
    "recordingsStore.isLive": {
      handler(v, old) {
        if (v) {
          this.recordingsStore.selectedTimestamp = new Date().toISOString()
        }
        if (!v && old && this.latestSnapshotTimestamp) {
          this.wasLive = true
          this.recordingsStore.selectedTimestamp = this.latestSnapshotTimestamp
          this.$setTimeout(() => {
            this.wasLive = false
          }, 1000)
        }
      },
      immediate: true,
      deep: true,
    },
    "recordingsStore.isEditing"(v) {
      this.hideTimeline = v
      const translationStart = v ? -this.layoutStore.footerRect.height : 0
      const translationEnd = v ? 0 : -this.layoutStore.footerRect.height
      this.editorTranslation = translationStart
      this.$nextTick(() => {
        this.$setTimeout(() => {
          this.editorTranslation = translationEnd
        })
      })
    },
    "recordingsStore.isXrayActive"(v) {
      if (!v) {
        this.isXrayInitialized = false
      } else {
        this.$setTimeout(() => {
          this.isXrayInitialized = true
        }, 1000)
      }
    },
    "timelineStore.showOverlay"(v) {
      // pause the live view on overlay
      if (v) {
        if (this.recordingsStore.isLive) {
          this.wasLive = true
          this.recordingsStore.isLive = false
        } else {
          this.wasLive = false
        }
      } else {
        if (this.wasLive) {
          this.recordingsStore.isLive = true
        }
      }
    },
  },
  mounted() {
    this.$addEventListener("keydown", this.onKeyHandler)
    this.$addEventListener("keyup", this.onKeyHandler)
    this.$analytics.saveEvent(AnalyticsEvent.pageView)
    if (!this.$permissions.user.is.admin()) {
      this.$posthog.startSessionRecording()
    }
  },
  destroyed() {
    this.layoutStore.disableRightSidebar()
    this.recordingsStore.reset()
    this.compareStore.$reset()
    this.resetTimelineStores()
    clearInterval(this.pollingIntervalId)
    clearQuery()
    if (!this.$permissions.user.is.admin()) {
      this.$posthog.stopSessionRecording()
    }
  },
  methods: {
    onSnapshotEdgeReached() {
      if (this.recordingsStore.isLive) {
        return
      }
      this.timelineStore.updatePlayerSnapshotsInterval()
    },
    onPlaybackToggle(isPlaying) {
      this.recordingsStore.isPlaying = isPlaying
      if (isPlaying) {
        this.timelineCommentsGroupStore.visibleComments = []
      } else {
        this.timelineCommentsGroupStore.filterCommentsByInterval(
          this.timelineStore.snapshotsInterval
        )
      }
    },
    onForbiddenTimestampClicked(selectedTimestamp: string) {
      this.$notifications.error({
        text: this.$t("content.etimeline.snapshot_not_found"),
        error: this.$t("content.etimeline.snapshot_not_found"),
        notifyParams: {
          ERROR: this.$t("content.etimeline.snapshot_not_found"),
          REQUEST_PAYLOAD: {
            selectedTimestamp,
          },
          FEATURE: "timeline",
        },
      })
    },
    onSnapshotsNotFound(selectedTimestamp: string) {
      this.timelineStore.selectTimestamp(this.previousTimestamp)
      this.$notifications.error({
        text: this.$t("content.etimeline.snapshot_not_found"),
        error: this.$t("content.etimeline.snapshot_not_found"),
        notifyParams: {
          ERROR: this.$t("content.etimeline.snapshot_not_found"),
          REQUEST_PAYLOAD: {
            selectedTimestamp,
          },
          FEATURE: "timeline",
        },
      })
    },
    onKeyHandler(e) {
      const isCopilotShortcut =
        e.ctrlKey &&
        e.shiftKey &&
        ["k", "K"].includes(e.key) &&
        e.type === "keydown"

      if (isCopilotShortcut) {
        this.timelineStore.toggleCopilotChat()
      }
    },
    resetTimelineStores() {
      this.timelineStore.$reset()
      this.timeline360GroupStore.$reset()
      this.timelineBimGroupStore.$reset()
      this.timelineAnprGroupStore.$reset()
      this.timelineDroneGroupStore.$reset()
      this.timelineExNvrGroupStore.$reset()
      this.timelineMediaHubGroupStore.$reset()
      this.timelineMobileCaptureGroupStore.$reset()
      this.timelineSmartMilestonesGroupStore.$reset()
      this.timelineSiteActivityGroupStore.$reset()
      this.timelineSafetyReportsGroupStore.$reset()
      this.timelineCommentsGroupStore.$reset()
      this.timelineConstructionReportsGroupStore.$reset()
    },
    enableBimCompare() {
      this.timelineStore.showOverlay = true
      this.timelineStore.overlayType = TimelineGroupId.Bim
    },
    async enableCompare() {
      this.compareStore.$reset()
      this.timelineStore.showOverlay = true
      this.timelineStore.overlayType = "compare"
      await this.timelineStore.updateSnapshotStoreIfNeeded()
      this.compareStore.beforeTimestamp =
        this.snapshotStore.oldestSnapshotTimestamp
      this.compareStore.afterTimestamp =
        this.recordingsStore.selectedTimestamp ||
        this.snapshotStore.latestSnapshotTimestamp
      this.recordingsStore.changeXrayVisibility(false)
    },
    onUserSelectedTimestamp(t: string, previousTimestamp: string) {
      this.previousTimestamp = previousTimestamp
      this.timelineStore.selectTimestamp(t)
      this.hasUserSelectedADate = true
    },
    getFormattedTimestamp(t: string): string {
      return this.$moment
        .tz(t, this.timelineStore.timezone)
        .format("YYYY-MM-DD HH:mm:ss")
    },
    onCameraChange(c: Camera) {
      this.timelineStore.selectCamera(c.id)
    },
    onCopilotCameraMentioned(cameraExid?: CameraExid) {
      if (cameraExid) {
        this.timelineStore.selectCamera(cameraExid)
      }
    },
    onTimelineResized({ contentRect }) {
      this.layoutStore.footerRect = contentRect
    },
    onGroupVisibilityChange({
      groupId,
      value,
    }: {
      groupId: TimelineGroupId
      value: boolean
    }) {
      this.hasUserInteractedWithFilters = true
      const v = !!value

      switch (groupId) {
        case TimelineGroupId._360:
          this.timeline360GroupStore.change360WalksVisibility(v)
          break
        case TimelineGroupId.Drone:
          this.timelineDroneGroupStore.changeDroneFlightsVisibility(v)
          break
        case TimelineGroupId.Bim:
          this.timelineBimGroupStore.changeBimeMilestonesVisibility(v)
          break
        case TimelineGroupId.Media:
          this.timelineMediaHubGroupStore.changeMediaHubVisibility(v)
          break
        case TimelineGroupId.Anpr:
          this.timelineAnprGroupStore.changeAnprVisibility(v)
          break
        case TimelineGroupId.SiteActivity:
          this.timelineSiteActivityGroupStore.changeSiteActivityVisibility(v)
          break
        case TimelineGroupId.SafetyReports:
          this.timelineSafetyReportsGroupStore.changeSafetyReportsVisibility(v)
          break
        case TimelineGroupId.ConstructionReports:
          this.timelineConstructionReportsGroupStore.changeConstructionReportsVisibility(
            v
          )
          break
        case TimelineGroupId.MobileCapture:
          this.timelineMobileCaptureGroupStore.changeMobileCaptureVisibility(v)
          break
        case TimelineGroupId.SmartMilestones:
          this.timelineSmartMilestonesGroupStore.changeSmartMilestonesVisibility(
            v
          )
          break
        case TimelineGroupId.ExNvrRecordings:
          this.timelineExNvrGroupStore.changeExNvrRecordingsVisibility(v)
          break
        case TimelineGroupId.Comments:
          this.timelineCommentsGroupStore.changeCommentsVisibility(v)
          break
      }
    },
    onTimelineIntervalChange: debounce(function ({ fromDate, toDate }) {
      if (this.recordingsStore.isLive) {
        return
      }
      this.timelineStore.fromDate = fromDate
      this.timelineStore.toDate = toDate
    }, 500),
    onMarkerDragStart() {
      this.isDraggingMarker = true
    },
    onMarkerDragDrag({
      marker,
      newTimestamp,
    }: {
      marker: TimelineMarker
      newTimestamp: string
    }) {
      if (this.isDOMUpdateScheduled) {
        return
      }
      this.isDOMUpdateScheduled = true
      requestAnimationFrame(() =>
        this.updateCompareMarkerLabel(marker, newTimestamp)
      )
    },
    async onMarkerDragEnd({ marker, newTimestamp }) {
      if (marker.timestamp === newTimestamp) {
        return
      }
      if (marker.id === TimelineMarkerId.CurrentSnapshot) {
        this.onUserSelectedTimestamp(newTimestamp, marker.timestamp)
        this.$analytics.saveEvent(AnalyticsEvent.xrayMoveAfterMarker)
      } else if (marker.id === TimelineMarkerId.Xray) {
        this.$analytics.saveEvent(AnalyticsEvent.xrayMoveBeforeMarker)
        this.recordingsStore.selectedXrayTimestamp = newTimestamp
      } else if (marker.id === TimelineMarkerId.CompareBeforeSnapshot) {
        this.compareStore.beforeTimestamp = newTimestamp
        this.$analytics.saveEvent(AnalyticsEvent.compareMoveBeforeMarker)
      } else if (marker.id === TimelineMarkerId.CompareAfterSnapshot) {
        this.compareStore.afterTimestamp = newTimestamp
        this.$analytics.saveEvent(AnalyticsEvent.compareMoveAfterMarker)
      }
    },
    updateCompareMarkerLabel(marker: TimelineMarker, newTimestamp: string) {
      const markersRefs = {
        [TimelineMarkerId.CompareBeforeSnapshot]: "beforeMarker",
        [TimelineMarkerId.CompareAfterSnapshot]: "afterMarker",
        [TimelineMarkerId.CurrentSnapshot]: "snapshotMarker",
        [TimelineMarkerId.Xray]: "xrayMarker",
      }
      const container = (this.$refs?.[markersRefs?.[marker?.id]] as Vue)?.$el

      if (!container) {
        return
      }
      const labelSelector = `.${this.compareMarkerlabelClass}`
      const label = container.querySelector(labelSelector) as HTMLElement

      if (label) {
        label.innerText = this.getFormattedTimestamp(newTimestamp)
      }
      this.isDOMUpdateScheduled = false
    },
    onMilestoneClicked<T extends TimelineOverlayType>({
      milestone,
      milestoneType,
    }: {
      milestone: TimelineOverlayData<T>
      milestoneType: T
    }) {
      if (milestoneType === TimelineGroupId.Comments) {
        this.timelineCommentsGroupStore.selectComment(
          milestone as TimelineOverlayData<TimelineGroupId.Comments>
        )

        return
      }
      this.clearOverlayDataFromUrl()
      this.timelineStore.showOverlay = false
      this.timelineStore.overlayType = milestoneType
      this.timelineStore.overlayData = milestone
      this.$analytics.saveEvent(AnalyticsEvent.timemlineSelectMilestoneItem, {
        milestoneType,
        timestamp: milestone?.timestamp,
      })
      this.$nextTick(() => {
        this.timelineStore.showOverlay = true
      })
    },
    onEventClicked({
      event,
      type,
    }: {
      event: TimelineEvent
      type: TimelineGroupId
    }) {
      let targetTime = event.timestamp
      if (type === TimelineGroupId.Anpr) {
        targetTime = this.$moment(event.timestamp)
          .subtract(5, "second")
          .toISOString()
      }
      this.goToTimestamp(targetTime)
    },
    onSmartMilestoneHovered(id?: string | number) {
      this.activeMarkerId = id ? `${id}` : undefined
    },
    goToTimestamp(timestamp: string) {
      this.$analytics.saveEvent(AnalyticsEvent.copilotClickTimestamp)
      this.focusedTimestamp = timestamp
      this.timelineStore.selectTimestamp(timestamp)
    },
    hideOverlay() {
      this.clearOverlayDataFromUrl()
      this.timelineStore.changeCompareVisibility(false)
      this.timelineBimGroupStore.changeBimeMilestonesVisibility(false)
      this.timelineStore.overlayType = null
      this.timelineStore.showOverlay = false
    },
    clearOverlayDataFromUrl() {
      const urlParamsEnum =
        this.urlParamsEnums[this.timelineStore.overlayType] || {}

      if (Object.keys(urlParamsEnum)?.length) {
        clearParamsFromQuery(urlParamsEnum)
      }
    },
    initOverlayDataFromUrl() {
      const urlParamsEnum =
        this.urlParamsEnums[this.timelineStore.overlayType] || {}

      if (Object.keys(urlParamsEnum)?.length) {
        this.timelineStore.overlayData = extractParamsFromQuery(
          urlParamsEnum
        ) as TimelineOverlayData<TimelineGroupId.Drone | TimelineGroupId._360>
        this.timelineStore.showOverlay = true
      }
    },
    deleteComment(comment) {
      this.timelineCommentsGroupStore.deleteComment(
        this.timelineStore.selectedProject.exid,
        comment.id
      )
      this.timelineCommentsGroupStore.selectedComment = null
    },
    onTimelineActionButtonsHovered(v) {
      this.isActionButtonsMenuHovered = v
    },
  },
})
